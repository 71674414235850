import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import { store } from 'react-notifications-component';
import emailjs from 'emailjs-com';

import './Contact.css';
import 'primereact/resources/primereact.css';

const Contact = () => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const emailjsConfig = {
        serviceID: 'gmail',
        templateID: 'robertene.com_template',
        userID: 'user_62iWvFJmqUZgBlIMsUC2l',
    };

    const { t } = useTranslation();
    const emailRegExp = new RegExp(emailPattern);

    const [state, setState] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const sendContactForm = (event) => {
        event.preventDefault();

        if (!handleChange()) {
            showNotification('danger', t('contact.notification.error'));
        } else {
            sendMessage(event);
            setState(false);
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
            showNotification('success', t('contact.notification.success'));
        }
    };

    const handleChange = () => {
        setState(true);
        return (
            checkValidField(name) &&
            checkValidEmail(email) &&
            checkValidField(subject) &&
            checkValidField(message)
        );
    };

    const checkValidField = (value) => {
        return value.trim().length > 0;
    };

    const checkValidEmail = (email) => {
        return email.trim().length > 0 && emailRegExp.test(email);
    };

    const sendMessage = (event) => {
        emailjs.sendForm(
            emailjsConfig.serviceID,
            emailjsConfig.templateID,
            event.target,
            emailjsConfig.userID
        );
    };

    const showNotification = (type, message) => {
        store.addNotification({
            type: type,
            title: t('contact.notification.header'),
            message: message,
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true,
            },
        });
    };

    return (
        <section id='contact'>
            <div className='row section-head'>
                <div className='two columns header-col'>
                    <h2>
                        <span>{t('contact.header')}</span>
                    </h2>
                </div>

                <div className='ten columns'>
                    <p className='lead jusified-text'>
                        {t('resume-data:main.contactmessage')}
                    </p>
                </div>
            </div>

            <div className='row'>
                <div className='eight columns'>
                    <form id='contactForm' name='contactForm' onSubmit={sendContactForm}>
                        <fieldset>
                            <div>
                                <label htmlFor='name'>
                                    {t('contact.name')}{' '}
                                    <span className='required'>*</span>
                                </label>
                                <InputText
                                    className={`${
                                        state && checkValidField(name) ? 'is-valid' : ''
                                    } ${
                                        state && !checkValidField(name)
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    type='text'
                                    id='name'
                                    name='name'
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setState(true);
                                    }}
                                    tooltip={
                                        state && !checkValidField(name)
                                            ? `${t('contact.feedback.name')}`
                                            : ''
                                    }
                                    tooltipOptions={{
                                        position: 'bottom',
                                    }}
                                />
                            </div>

                            <div>
                                <label htmlFor='email'>
                                    {t('contact.email')}{' '}
                                    <span className='required'>*</span>
                                </label>
                                <InputText
                                    className={`${
                                        state && checkValidEmail(email) ? 'is-valid' : ''
                                    } ${
                                        state && !checkValidEmail(email)
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    type='text'
                                    id='email'
                                    name='email'
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setState(true);
                                    }}
                                    tooltip={
                                        state && !checkValidEmail(email)
                                            ? `${t('contact.feedback.email')}`
                                            : ''
                                    }
                                    tooltipOptions={{
                                        position: 'bottom',
                                    }}
                                />
                            </div>

                            <div>
                                <label htmlFor='subject'>
                                    {t('contact.subject')}{' '}
                                    <span className='required'>*</span>
                                </label>
                                <InputText
                                    className={`${
                                        state && checkValidField(subject)
                                            ? 'is-valid'
                                            : ''
                                    } ${
                                        state && !checkValidField(subject)
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    type='text'
                                    id='subject'
                                    name='subject'
                                    value={subject}
                                    onChange={(e) => {
                                        setSubject(e.target.value);
                                        setState(true);
                                    }}
                                    tooltip={
                                        state && !checkValidField(subject)
                                            ? `${t('contact.feedback.subject')}`
                                            : ''
                                    }
                                    tooltipOptions={{
                                        position: 'bottom',
                                    }}
                                />
                            </div>

                            <div>
                                <label htmlFor='message'>
                                    {t('contact.message')}{' '}
                                    <span className='required'>*</span>
                                </label>
                                <InputTextarea
                                    className={`${
                                        state && checkValidField(message)
                                            ? 'is-valid'
                                            : ''
                                    } ${
                                        state && !checkValidField(message)
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                    cols={50}
                                    rows={15}
                                    id='message'
                                    name='message'
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                        setState(true);
                                    }}
                                    tooltip={
                                        state && !checkValidField(message)
                                            ? `${t('contact.feedback.message')}`
                                            : ''
                                    }
                                    tooltipOptions={{
                                        position: 'bottom',
                                    }}
                                />
                            </div>

                            <div>
                                <button type='submit' className='submit'>
                                    {t('contact.submit')}
                                </button>
                            </div>
                        </fieldset>
                    </form>
                </div>

                <aside className='four columns footer-widgets'>
                    <div className='widget widget_contact'>
                        <h4>{t('contact.addressPhone')}</h4>
                        <p className='address' itemProp='address'>
                            {t('resume-data:main.name')}
                            <br />
                            {t('resume-data:main.address.city')} (
                            {t('resume-data:main.address.state')}),{' '}
                            {t('resume-data:main.address.country')}
                            <br />
                            {t('resume-data:main.phone')}
                            <br />
                            <span>
                                <a
                                    href={`mailto:${t('resume-data:main.email')}`}
                                    itemProp='email'
                                >
                                    {t('resume-data:main.email')}
                                </a>
                            </span>
                        </p>
                    </div>
                </aside>
            </div>
        </section>
    );
};

export default Contact;
