import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactNotification from 'react-notifications-component';
import ReactGA from 'react-ga4';

import Header from './components/header/Header';
import About from './components/about/About';
import Resume from './components/resume/Resume';
import Portfolio from './components/portfolio/Portfolio';
import Testimonials from './components/testimonials/Testimonials';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

import './App.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-notifications-component/dist/theme.css';

const App = () => {
    ReactGA.initialize([
        {
            trackingId: 'G-JHHNFDPK9P',
        },
        {
            trackingId: 'UA-70817919-2',
        },
    ]);
    ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
    });

    const delay = (ms) => {
        return new Promise((res) => setTimeout(res, ms));
    };

    useEffect(() => {
        const importScript = async (url) => {
            await delay(100);
            const script = document.createElement('script');
            script.src = url;
            document.body.appendChild(script);
        };
        importScript('/js/init.js');
    }, []);

    const { i18n } = useTranslation();
    const language = i18n.language.split('-')[0];

    return (
        <div itemProp='person' itemScope itemType='https://schema.org/Person'>
            <BrowserRouter>
                <Switch>
                    <Route exact path='/(en|es)'>
                        <ReactNotification />
                        <Header />
                        <About />
                        <Resume />
                        <Portfolio />
                        <Testimonials />
                        <Contact />
                        <Footer />
                    </Route>
                    <Route
                        path='*'
                        render={() => {
                            return <Redirect to={'/' + language} />;
                        }}
                    />
                </Switch>
            </BrowserRouter>
        </div>
    );
};

export default App;
