import i18next from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next
    .use(I18NextHttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        ns: ['translation', 'resume-data'],
        defaultNS: 'translation',
        fallbackLng: 'en',
        supportedLngs: ['en', 'en-us', 'en-gb,', 'es', 'es-es'],
        detection: {
            order: ['path', 'navigator'],
            caches: [],
        },
        lowerCaseLng: true,
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
        },
        debug: false,
    });

export default i18next;
