import React from 'react';
import { useTranslation } from 'react-i18next';

import './About.css';

const About = () => {
    const { t } = useTranslation();

    return (
        <section id='about'>
            <div className='row'>
                <div className='three columns'>
                    <img
                        className='profile-pic'
                        src={`images/${t('resume-data:main.image')}`}
                        alt={`${t('about.profilePicDescription')}`}
                        itemProp='image'
                    />
                </div>
                <div className='nine columns main-col'>
                    <h2>{t('about.header')}</h2>

                    <p className='justified-text' itemProp='description'>
                        {t('resume-data:main.bio')}
                    </p>
                    <div className='row'>
                        <div className='columns contact-details'>
                            <h2>{t('about.contactDetails')}</h2>
                            <p className='address'>
                                <span itemProp='name'>{t('resume-data:main.name')}</span>
                                <br />
                                <span itemProp='address'>
                                    {t('resume-data:main.address.city')} (
                                    {t('resume-data:main.address.state')}),{' '}
                                    {t('resume-data:main.address.country')}
                                </span>
                                <br />
                                <span itemProp='telephone'>
                                    {t('resume-data:main.phone')}
                                </span>
                                <br />
                                <span>
                                    <a
                                        href={`mailto:${t('resume-data:main.email')}`}
                                        itemProp='email'
                                    >
                                        {t('resume-data:main.email')}
                                    </a>
                                </span>
                            </p>
                        </div>
                        <div className='columns download'>
                            <p>
                                <a
                                    href={t('resume-data:main.resumedownload')}
                                    className='button'
                                    itemProp='url'
                                >
                                    <i className='fa fa-download'></i>
                                    {t('about.downloadResume')}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
