import React from 'react';
import { useTranslation } from 'react-i18next';
import renderHTML from 'react-render-html';

import './Header.css';

const Header = () => {
    const { t } = useTranslation();

    const getNetworks = (social) => {
        return social.map((network, index) => {
            return (
                <li key={index}>
                    <a href={network.url} itemProp='url' aria-label={network.ariaLabel}>
                        <i className={network.className}></i>
                    </a>
                </li>
            );
        });
    };

    return (
        <header id='home'>
            <nav id='nav-wrap'>
                <a className='mobile-btn' href='#nav-wrap' title={t('header.nav.show')}>
                    {t('header.nav.show')}
                </a>
                <a className='mobile-btn' href='#home' title={t('header.nav.hide')}>
                    {t('header.nav.hide')}
                </a>

                <ul id='nav' className='nav'>
                    <li className='current'>
                        <a className='smoothscroll' href='#home'>
                            {t('header.nav.home')}
                        </a>
                    </li>
                    <li>
                        <a className='smoothscroll' href='#about'>
                            {t('header.nav.about')}
                        </a>
                    </li>
                    <li>
                        <a className='smoothscroll' href='#resume'>
                            {t('header.nav.resume')}
                        </a>
                    </li>
                    <li>
                        <a className='smoothscroll' href='#portfolio'>
                            {t('header.nav.portfolio')}
                        </a>
                    </li>
                    <li>
                        <a className='smoothscroll' href='#testimonials'>
                            {t('header.nav.testimonials')}
                        </a>
                    </li>
                    <li>
                        <a className='smoothscroll' href='#contact'>
                            {t('header.nav.contact')}
                        </a>
                    </li>
                </ul>
            </nav>

            <div className='row banner'>
                <div className='banner-text'>
                    <h1 className='responsive-headline'>
                        {t('header.im')}{' '}
                        <span itemProp='name'>{t('resume-data:main.name')}</span>.
                    </h1>
                    <h3 itemProp='description'>
                        {renderHTML(
                            t('resume-data:main.description', {
                                country: t('resume-data:main.address.country'),
                                occupation: t('resume-data:main.occupation'),
                            })
                        )}
                    </h3>
                    <hr />
                    <ul className='social'>
                        {getNetworks(
                            t('resume-data:main.social', { returnObjects: true })
                        )}
                    </ul>
                </div>
            </div>

            <p className='scrolldown'>
                <a className='smoothscroll' href='#about'>
                    <i className='icon-down-circle'></i>
                </a>
            </p>
        </header>
    );
};

export default Header;
