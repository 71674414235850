import React from 'react';
import { useTranslation } from 'react-i18next';

import './Portfolio.css';

const Portfolio = () => {
    const { t } = useTranslation();

    const getClients = (clients) => {
        return clients.map((client, index) => {
            return (
                <div key={index} className='columns portfolio-item'>
                    <div
                        className='item-wrap'
                        itemProp='worksFor'
                        itemScope
                        itemType='https://schema.org/Organization'
                    >
                        <a
                            href={client.url}
                            title={client.title}
                            target='_blank'
                            rel='noopener noreferrer'
                            itemProp='url'
                        >
                            <img
                                alt={client.title}
                                src={`images/portfolio/clients/${client.image}`}
                                itemProp='logo'
                            />
                            <div className='overlay'></div>
                        </a>
                    </div>
                </div>
            );
        });
    };

    return (
        <section id='portfolio'>
            <div className='row'>
                <div className='twelve columns collapsed'>
                    <h2>{t('portfolio.clients')}</h2>
                    <div
                        id='portfolio-wrapper'
                        className='bgrid-quarters s-bgrid-thirds cf'
                    >
                        {getClients(
                            t('resume-data:portfolio.clients', { returnObjects: true })
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
