import React from 'react';
import { useTranslation } from 'react-i18next';

import './Testimonials.css';

const Testimonials = () => {
    const { t } = useTranslation();

    const getTestimonials = (testimonials) => {
        return testimonials.map((testimonial, index) => {
            return (
                <li key={index}>
                    <blockquote>
                        <p className='justified-text'>{testimonial.text}</p>
                        <cite
                            itemProp='knows'
                            itemScope
                            itemType='https://schema.org/Person'
                        >
                            {testimonial.user}
                        </cite>
                    </blockquote>
                </li>
            );
        });
    };

    return (
        <section id='testimonials'>
            <div className='text-container'>
                <div className='row'>
                    <div className='two columns header-col'>
                        <h2>
                            <span>{t('testimonials.header')}</span>
                        </h2>
                    </div>

                    <div className='ten columns flex-container'>
                        <ul className='slides'>
                            {getTestimonials(
                                t('resume-data:testimonials.testimonials', {
                                    returnObjects: true,
                                })
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
