import React from 'react';
import { useTranslation } from 'react-i18next';

import './Resume.css';

function Resume() {
    const { t } = useTranslation();

    const getEducation = (education) => {
        return education.map((education, index) => {
            return (
                <div
                    key={index}
                    itemProp='alumniOf'
                    itemScope
                    itemType='https://schema.org/EducationalOrganization'
                >
                    <h3 itemProp='name'>{education.school}</h3>
                    <p className='info'>
                        {education.degree} <span>&bull;</span>
                        <em className='date'>{education.graduated}</em>
                    </p>
                    <p className='justified-text'>{education.description}</p>
                </div>
            );
        });
    };

    const getWork = (work) => {
        return work.map((work, index) => {
            return (
                <div
                    key={index}
                    itemProp='employee'
                    itemScope
                    itemType='https://schema.org/Organization'
                >
                    <h3 itemProp='name'>{work.company}</h3>
                    <p className='info'>
                        {work.title}
                        <span>&bull;</span> <em className='date'>{work.years}</em>
                    </p>
                    <p className='justified-text'>{work.description}</p>
                </div>
            );
        });
    };

    const getFavoriteTech = (favoritetech) => {
        return favoritetech.map((tech) => {
            return (
                <div
                    key={tech.name}
                    itemProp='knowsAbout'
                    itemScope
                    itemType='https://schema.org/Occupation'
                >
                    <div className='tech-wrapper' itemProp='skills'>
                        <img
                            className='tech-logo'
                            alt={tech.name}
                            src={`images/tech/${tech.image}`}
                            itemProp='image'
                        />
                        <div className='tech-experience'>
                            <h5 itemProp='name'>{tech.name}</h5>
                            <div className='tech-bar'>
                                <span
                                    style={{ width: tech.level }}
                                    className={`bar-expand ${tech.name.toLowerCase()}`}
                                ></span>
                            </div>
                        </div>
                    </div>
                    <p className='justified-text' itemProp='description'>
                        {tech.description}
                    </p>
                </div>
            );
        });
    };

    return (
        <section id='resume'>
            <div className='row work'>
                <div className='three columns header-col'>
                    <h2>
                        <span>{t('resume.work')}</span>
                    </h2>
                </div>

                <div className='nine columns main-col'>
                    {getWork(t('resume-data:resume.work', { returnObjects: true }))}
                </div>
            </div>

            <div className='row education'>
                <div className='three columns header-col'>
                    <h2>
                        <span>{t('resume.education')}</span>
                    </h2>
                </div>

                <div className='nine columns main-col'>
                    <div className='row item'>
                        <div className='twelve columns'>
                            {getEducation(
                                t('resume-data:resume.education', { returnObjects: true })
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='row skill'>
                <div className='three columns header-col'>
                    <h2>
                        <span>{t('resume.favoriteTech')}</span>
                    </h2>
                </div>
                <div className='nine columns header-col'>
                    <p className='lead center'>{t('resume-data:resume.skillmessage')}</p>
                </div>
                <div className='twelve columns favorite-tech'>
                    {getFavoriteTech(
                        t('resume-data:resume.favoritetech', { returnObjects: true })
                    )}
                </div>
            </div>
        </section>
    );
}

export default Resume;
