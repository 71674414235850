import React from 'react';
import { useTranslation } from 'react-i18next';

import './Footer.css';

const Footer = () => {
    const { t } = useTranslation();

    const getNetworks = (social) => {
        return social.map((network, index) => {
            return (
                <li key={index}>
                    <a href={network.url} itemProp='url'>
                        <i className={network.className}></i>
                    </a>
                </li>
            );
        });
    };

    return (
        <footer>
            <div className='row'>
                <div className='twelve columns'>
                    <ul className='social-links'>
                        {getNetworks(
                            t('resume-data:main.social', { returnObjects: true })
                        )}
                    </ul>

                    <ul className='copyright'>
                        <li itemProp='author'>&copy; Copyright 2024 Robert Ene</li>
                    </ul>
                </div>
                <div id='go-top'>
                    <a className='smoothscroll' title='Back to Top' href='#home'>
                        <i className='icon-up-open'></i>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
