import React, { StrictMode, Suspense } from 'react';
import ReactLoaderSpinner from 'react-loader-spinner';
import ReactDOM from 'react-dom';

import App from './App';

import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.css';

ReactDOM.render(
    <StrictMode>
        <Suspense
            fallback={
                <ReactLoaderSpinner
                    className='loader'
                    type='Rings'
                    color='#F06000'
                    height={100}
                    width={100}
                />
            }
        >
            <App />
        </Suspense>
    </StrictMode>,
    document.getElementById('root')
);

serviceWorkerRegistration.register();
